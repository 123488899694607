<template>
  <div id="overall" class="online_courses_public">
    <PageSubHeader :pageSubHeader="pageSubHeader" :key="componentKey"/>

    <div class=" title_wrap">
      <div class="w1100 container">
        <div class="title">
          <h3 class="txt-bold">課程總覽</h3>
          <img src="@/statics/img/index/icon_title2.svg" alt="">
        </div>
        <form id="courses_filter" @submit.prevent>
          <div class="filter">
            <div class="select_box">
              <small class="select_title txt-bold">積分類型</small>
              <select
                id="point_type"
                name="point_type"
                class="p"
                v-model="params.type_id"
                @change="filterData()"
              >
                <option value="all">全部</option>
                <option
                  v-for="selectOption in selectOptions"
                  :key="selectOption.id"
                  :value="selectOption.type"
                >{{ selectOption.name }}</option>
              </select>
            </div>
            <div class="input-group">
              <label for="keyword" class="small txt-bold">搜尋課程名稱</label>
              <div class="input-wrap">
                <input
                  id="keyword"
                  name="keyword"
                  type="text"
                  placeholder="標題"
                  class="p"
                  v-model.trim="params.keyword"
                >
                <button
                  type="button"
                  class="p"
                  @click="filterData()"
                >查詢</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="content">
      <div class="w1100">
        <ul class="event_list">
          <li v-for="result in resultArray" :key="result.id">
            <router-link
              :to="`/register/online/mycourse/info?course_id=${result.uuid}`"
              class="cards_item"
            >
              <div class="wrap top">
                <div class="col100">
                  <div class="card_title_wrapper">
                    <p class="card_title">{{ result.name }}</p>
                    <span class="episode"><small>{{ result.video_count }}集</small></span>
                  </div>
                </div>
                <div class="col100 bottom">
                  <div class="col70 course_info course_price">
                    <div class="fraction_box">
                      <p
                        class="small type-A"
                        v-show="result.fraction_a && result.fraction_a * 1 !== 0"
                      >
                        <span class="tag">A類</span>
                        <span class="credit">．{{ result.fraction_a }}學分</span>
                      </p>
                      <p
                        class="small type-B"
                        v-show="result.fraction_b && result.fraction_b * 1 !== 0"
                      >
                        <span class="tag">B類</span>
                        <span class="credit">．{{ result.fraction_b }}學分</span>
                      </p>
                    </div>
                    <p class="time ls1 small">課程時長: {{ result.length }}</p>
                    <p class="period ls1 small">
                      觀看期限: 付款成功後 {{ result.expiry_time }} 天內
                    </p>
                    <!-- <div class="course_price_detail">
                      <p class="small" v-if="result.price[2] && result.price[2].price">會員醫師：
                        <span class="txt-dark_green txt-bold">${{ result.price[2].price }}</span>
                      </p>
                      <p class="small" v-if="result.price[3] && result.price[2].price">非會員醫師：
                        <span class="txt-dark_green txt-bold">${{ result.price[3].price }}</span>
                      </p>
                      <p class="small" v-if="result.price[4] && result.price[4].price">護理人員：
                        <span class="txt-dark_green txt-bold">${{ result.price[4].price }}</span>
                      </p>
                    </div> -->
                  </div>
                  <div class="col30">
                    <button
                    type="button"
                    class="addItem-btn"
                    :class="{ 'deleteItem-btn': isActive(result.uuid) }"
                    @click.prevent="handleCartItem(result.uuid)"
                    v-if="isAvailable(result)"
                    >
                      <small v-show="!isActive(result.uuid)">+加入清單</small>
                      <small v-show="isActive(result.uuid)">－移除課程</small>
                    </button>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>

        <Pagination
          :pageButton="pageButton"
          :page="params.page"
          :totalPage="totalPage"
          @updatePage="updatePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterOnlineSubHeader } from '@/lib/const';
import Pagination from '@/components/Pagination.vue';
import { mapGetters } from 'vuex';
import { courses } from '@/lib/http';
import { isExpired } from '@/lib/public';

export default {
  name: 'RegisterOnlineIndex',
  data() {
    return {
      /** Static Data */
      ...RegisterOnlineSubHeader,
      pageButton: 5,
      componentKey: 0,

      /** Search Params */
      params: {
        type_id: 'all',
        keyword: '',
        limit: 10,
        page: 1,
      },

      /** Axios Data */
      totalPage: 1,
      resultArray: [
        // {
        //   id: 1,
        //   title: '乙類安寧居家療護 - 109年度社區安寧照護(乙類)醫護人員教育訓練',
        //   episode: '13',
        //   tag: '',
        //   credits: '3',
        //   duration: '13 小時 00 分',
        //   limit: '1',
        //   priceList: [
        //     {
        //       id: '1',
        //       category: '會員醫師：',
        //       num: '3000',
        //     },
        //     {
        //       id: '2',
        //       category: '非會員醫師',
        //       num: '3000',
        //     },
        //     {
        //       id: '3',
        //       category: '護理人員',
        //       num: '3000',
        //     },
        //   ],
        // },
        // {
        //   id: 2,
        //   title: '109年度社區安寧照護醫護人員教育訓練',
        //   episode: '13',
        //   tag: 'A',
        //   credits: '3',
        //   duration: '13 小時 00 分',
        //   limit: '1',
        //   priceList: [
        //     {
        //       id: '1',
        //       category: '會員醫師：',
        //       num: '3000',
        //     },
        //     {
        //       id: '2',
        //       category: '非會員醫師',
        //       num: '3000',
        //     },
        //     {
        //       id: '3',
        //       category: '護理人員',
        //       num: '3000',
        //     },
        //   ],
        // },
        // {
        //   id: 3,
        //   title: '109年度社區安寧照護醫護人員教育訓練',
        //   episode: '13',
        //   tag: 'B',
        //   credits: '3',
        //   duration: '13 小時 00 分',
        //   limit: '1',
        //   priceList: [
        //     {
        //       id: '1',
        //       category: '會員醫師：',
        //       num: '3000',
        //     },
        //     {
        //       id: '2',
        //       category: '非會員醫師',
        //       num: '3000',
        //     },
        //     {
        //       id: '3',
        //       category: '護理人員',
        //       num: '3000',
        //     },
        //   ],
        // },
      ],
    };
  },
  created() {
    if (this.$route.query.type_id) {
      this.params.type_id = this.$route.query.type_id;
    }
    if (this.$route.query.keyword) {
      this.params.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.num) {
      this.params.page = parseInt(this.$route.query.num, 10);
    }
    this.filterDataInit(this.params.page);
  },
  computed: {
    ...mapGetters([
      'activityType',
    ]),
    selectOptions() {
      if (this.activityType !== []) {
        return this.activityType.filter((item) => item.name === 'A' || item.name === 'B')
          .map((item) => ({
            ...item,
            type: item.name.toLowerCase(),
          }));
      }
      return [];
    },
  },
  methods: {
    /**
     * API Call：Online Index
     */
    filterDataInit(pageValue = 1) {
      courses({
        ...this.params,
        page: pageValue,
      }).then((result) => {
        if (Object.keys(result).length > 0) {
          this.totalPage = result.pageCount;
          this.resultArray = result.data;
          this.params.page = pageValue;
          this.$router.replace({
            query: {
              type_id: this.params.type_id,
              keyword: this.params.host_type,
              num: pageValue,
            },
          });
        } else {
          this.totalPage = 1;
          this.resultArray = [];
        }
      });
    },
    filterData(pageValue = 1) {
      courses({
        ...this.params,
        page: pageValue,
      }).then((result) => {
        if (Object.keys(result).length > 0) {
          this.totalPage = result.pageCount;
          this.resultArray = result.data;
          this.params.page = pageValue;
          this.$router.push({
            query: {
              type_id: this.params.type_id,
              keyword: this.params.host_type,
              num: pageValue,
            },
          });
        } else {
          this.totalPage = 1;
          this.resultArray = [];
        }
      });
    },
    isActive(courseId) {
      const courseIdArray = JSON.parse(this.$cookies.get('courseInCart'));
      // console.log(courseIdArray);
      if (courseIdArray !== null) {
        if (courseIdArray.indexOf(courseId) !== -1) {
          return true;
        }
        return false;
      }
      return false;
    },
    /**
     * 使用者登入狀態下，額外判斷總覽頁的個別課程是否可購買
     * 可購買類型（未曾買過 || 已失效）
     */
    isAvailable(course) {
      const courseCompleted = _.get(course, 'bought_completed', undefined);
      const courseExpiryTime = _.get(course, 'bought_expiry_time', undefined);

      if (courseCompleted !== undefined && courseExpiryTime !== undefined) {
        if (courseCompleted === null
        || (courseCompleted === false && isExpired(courseExpiryTime))) {
          return true;
        }
        return false;
      }
      return true;
    },
    handleCartItem(cartItemID) {
      const cookies = this.$cookies;
      let courseIdArray = [];

      if (cookies.get('courseInCart') !== null) {
        courseIdArray = JSON.parse(cookies.get('courseInCart'));
        if (courseIdArray.indexOf(cartItemID) !== -1) {
          courseIdArray.splice(courseIdArray.indexOf(cartItemID), 1);
        } else {
          courseIdArray.push(cartItemID);
        }
        cookies.set('courseInCart', JSON.stringify(courseIdArray));
      } else {
        courseIdArray.push(cartItemID);
        cookies.set('courseInCart', JSON.stringify(courseIdArray));
      }
      // console.log(JSON.parse(cookies.get('courseInCart')));
      this.forceRerender();
      this.$forceUpdate();
    },
    updatePage(value) {
      // this.page = value;
      // this.$router.push({ query: { num: value } });
      this.filterData(value);
    },
    /**
     * Force PageSubheader To Update Its Count
     */
    forceRerender() {
      this.componentKey += 1;
    },
    isExpired,
  },
  watch: {
    /**
     * 偵測user使用網頁的上一頁功能時，重設頁數及重call API
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.params.page = parseInt(this.$route.query.num, 10);
      this.filterData(this.params.page);
    },
    // page() {
    //   this.filterData();
    // },
  },
  components: {
    PageSubHeader,
    Pagination,
  },
};
</script>

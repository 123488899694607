<template>
  <div id="pagination">
    <button class="prev_start" v-show="page > 1" @click="startPage">
      <img src="@/statics/img/index/icon_arrow_r2.svg" alt="">
    </button>
    <button class="prev" v-show="page > 1" @click="changePage(-1)">
      <img src="@/statics/img/index/icon_arrow_r.svg" alt="">
    </button>
    <ul>
      <li v-for="pageNum in pageList" :key="pageNum" :class="{ active: pageNum === page }">
        <button @click="jumpPage(pageNum)">{{ pageNum }}</button>
      </li>
    </ul>
    <button class="next" v-show="page < totalPage" @click="changePage(1)">
      <img src="@/statics/img/index/icon_arrow_r.svg" alt="">
    </button>
    <button class="next_end" v-show="page < totalPage" @click="endPage">
      <img src="@/statics/img/index/icon_arrow_r2.svg" alt="">
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    // pageData: Array,
    // perPage: Number,
    pageButton: Number,
    page: Number,
    totalPage: Number,
  },
  data() {
    return {
    };
  },
  computed: {
    pageList() {
      /**
       * Page Buttons Per Time: [page-2, page-1, page, page+1, page+2]
       */
      const pageListArray = [];
      for (let i = 1; i < this.totalPage + 1; i += 1) {
        pageListArray.push(i);
      }
      if (this.pageButton >= this.totalPage) {
        return pageListArray;
      }
      const indexNow = this.page - 1;
      switch (true) {
        case this.page === 1:
          return pageListArray.slice(indexNow, indexNow + this.pageButton);
        case this.page === 2:
          return pageListArray.slice(indexNow - 1, indexNow + this.pageButton - 1);
        case this.page + 1 > this.totalPage:
          return pageListArray.slice(indexNow - this.pageButton + 1);
        case this.page + 2 > this.totalPage:
          return pageListArray.slice(indexNow - this.pageButton + 2);
        case this.page + 3 > this.totalPage:
          return pageListArray.slice(indexNow - 2);
        default:
          return pageListArray.slice(indexNow - Math.floor(this.pageButton / 2), indexNow
          + Math.ceil(this.pageButton / 2));
      }
      // if (this.page === 1) {
      //   return pageListArray.slice(indexNow, indexNow + this.pageButton);
      // }
      // if (this.page === 2) {
      //   return pageListArray.slice(indexNow - 1, indexNow + this.pageButton - 1);
      // }
      // if (this.page + 1 > this.totalPage) {
      //   return pageListArray.slice(indexNow - this.pageButton + 1);
      // }
      // if (this.page + 2 > this.totalPage) {
      //   return pageListArray.slice(indexNow - this.pageButton + 2);
      // }
      // if (this.page + 3 > this.totalPage) {
      //   return pageListArray.slice(indexNow - 2);
      // }
      // return pageListArray.slice(indexNow - Math.floor(this.pageButton / 2), indexNow
      // + Math.ceil(this.pageButton / 2));
    },
  },
  methods: {
    changePage(direct) {
      const page = this.page + direct;
      if (page < 1) {
        this.$emit('updatePage', 1);
        this.$router.push({ query: { num: 1 } });
      } else if (page > this.totalPage) {
        this.$emit('updatePage', this.totalPage);
      } else {
        this.$emit('updatePage', page);
      }
    },
    jumpPage(destination) {
      this.$emit('updatePage', destination);
    },
    startPage() {
      this.$emit('updatePage', 1);
    },
    endPage() {
      this.$emit('updatePage', this.totalPage);
    },
  },
};
</script>
